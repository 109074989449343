import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import SEO from '../components/SEO';
import Layout from '../layouts/index';


const Page = ({ data, locale, location }) => {
  const { title } = data.mdx.frontmatter || {};
  const { body } = data.mdx || {};
  return (
    <Layout bodyClass="page-service" locale={locale} inverted border location={location}>
      <SEO title={title} />
      <section className="bg-grey">
        <div className="container pt-4 pt-md-10">
          <div className="row justify-content-st
          art">
            <div className="col-12 ">
              <div className="service service-single">
                <h1 className="title">{title}</h1>
                {body ? (
                  <MDXRenderer>{body}</MDXRenderer>
                ) : (
                  <div>This page hasn't been translated yet</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};


export const query = graphql`
  query($locale: String!, $slug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        slug
        title
      }
      body
    }
  }
`

export default Page;
